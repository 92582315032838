import { createWebHistory, createRouter } from "vue-router";
import TermsView from "@/views/TermsView.vue";
import FormView from "@/views/FormView.vue";
import SuccessView from "@/views/SuccessView.vue";
import ConsultView from "@/views/ConsultView.vue";
import ChatView from "@/views/ChatView.vue";
import DetailsView from "@/views/DetailsView.vue";
import PathNotFound from "@/views/PathNotFound.vue";
const routes = [
  {
    path: "/empresas/:id/:type/termos",
    name: "content-terms",
    component: TermsView,
    meta: { description: "Página dos termos de uso.", label: "Termos" },
  },
  {
    path: "/empresas/:id/:type/formulario",
    name: "content",
    component: FormView,
    meta: {
      description: "Página que contem um formulário para um relato.",
      label: JSON.stringify({
        denuncia: "Detalhes da Denúncia",
        solicitacao: "Detalhes da Solicitação",
        sugestao: "Detalhes da Dúvida ou Sugestão",
      }),
    },
  },
  {
    path: "/empresas/:id/:type/sucesso",
    name: "content-success",
    component: SuccessView,
    meta: {
      description:
        "Página que contem o número de protocolo e alguns outros items relacionados ao protocolo de um relato.",
      label: "Enviado",
    },
  },
  {
    path: "/consultar",
    name: "consult",
    component: ConsultView,
    meta: {
      description: "Página de consulta de um relato",
      label: "Consultar Relato",
    },
  },
  {
    path: "/",
    name: "consult-home",
    component: ConsultView,
    meta: {
      description: "Página de consulta de um relato",
      label: "Consultar Relato",
    },
  },
  {
    path: "/chat/",
    name: "chat",
    component: ChatView,
    meta: {
      description: "Chat de um relato.",
      label: JSON.stringify({
        complaint: "Denúncia",
        request: "Solicitação",
        suggestion: "Dúvida ou Sugestão",
      }),
      type: "chat",
    },
  },
  {
    path: "/chat/detalhes",
    name: "details",
    component: DetailsView,
    meta: {
      description: "Página com os detalhes de um relato.",
      label: JSON.stringify({
        complaint: "Detalhes da Denúncia",
        request: "Detalhes da Solicitação",
        suggestion: "Detalhes da Dúvida ou Sugestão",
      }),
      type: "details",
    },
  },
  {
    path: "/:pathMatch(.*)*",
    meta:{
      label: "Página não encontrada",
    },
    name: "notfound",
    component: PathNotFound,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
