<template>
  <div id="main">
    <div class="text-left m-3" v-if="content.version === 2">
      <div v-for="field of content.fields" :key="field">
        <div class="font-[IBM600] text-[16px] my-4">
          {{ field.title }}
        </div>
        <div class="font-[IBM400] text-[16px]"  v-if="Array.isArray(field.model)">
          <div v-for="item of field.model" :key="item">
            {{ item?.name }}
          </div>
        </div>
        <div class="font-[IBM400] text-[16px]" v-else>
          {{ field.model }}
        </div>
      </div>
    </div>

    <div
      class="text-left m-3"
      v-if="content?.version === 1 && content.type === 'request'"
    >
      <div class="font-[IBM600] text-[16px] my-4" v-if="content?.right">
        {{ content?.right }}
      </div>
      <div
        class="font-[Roboto400] text-[12px] text-[#676767]"
        v-if="content?.details"
      >
        Detalhes da Solicitação
      </div>
      <div class="font-[IBM400] text-[16px]" v-if="content?.details">
        {{ content.details }}
      </div>

      <div
        class="font-[Roboto400] text-[12px] text-[#676767]"
        v-if="content?.createdAt"
      >
        Data da Solicitação
      </div>

      <div class="font-[IBM400] text-[16px]" v-if="content?.createdAt">
        {{ $filters.formatDate(content.createdAt) }}
      </div>

      <div class="font-[IBM600] text-[16px] my-4">Dados do autor</div>

      <div
        class="font-[Roboto400] text-[12px] text-[#676767]"
        v-if="content?.holder"
      >
        Tipo de Autor
      </div>

      <div class="font-[IBM400] text-[16px]" v-if="content?.holder">
        {{ content?.holder }}
      </div>

      <div
        class="font-[Roboto400] text-[12px] text-[#676767]"
        v-if="content?.name"
      >
        Autor
      </div>

      <div class="font-[IBM400] text-[16px]" v-if="content?.name">
        {{ content?.name }}
      </div>

      <div
        class="font-[Roboto400] text-[12px] text-[#676767]"
        v-if="content?.email"
      >
        E-mail
      </div>

      <div class="font-[IBM400] text-[16px]" v-if="content?.email">
        {{ content?.email }}
      </div>

      <div
        class="font-[Roboto400] text-[12px] text-[#676767]"
        v-if="content?.phone"
      >
        Telefone
      </div>

      <div class="font-[IBM400] text-[16px]" v-if="content?.phone">
        {{ content?.phone }}
      </div>

      <div class="font-[IBM600] text-[16px] my-4" v-if="content?.files?.lenght">
        Arquivos
      </div>

      <div v-if="content?.files?.lenght">
        {{ content?.files }}
      </div>
    </div>

    <div
      class="text-left m-3"
      v-if="content?.version === 1 && content.type === 'complaint'"
    >
      <div class="font-[IBM600] text-[16px] my-4" v-if="content?.subject">
        {{ content?.subject }}
      </div>

      <div
        class="font-[Roboto400] text-[12px] text-[#676767]"
        v-if="content?.factsDesc"
      >
        Descrição dos fatos
      </div>

      <div class="font-[IBM400] text-[16px]" v-if="content?.factsDesc">
        {{ content?.factsDesc }}
      </div>

      <div
        class="font-[Roboto400] text-[12px] text-[#676767]"
        v-if="content?.createdAt"
      >
        Data da Denúncia
      </div>

      <div class="font-[IBM400] text-[16px]" v-if="content?.createdAt">
        {{ $filters.formatDate(content.createdAt) }}
      </div>

      <div
        v-if="
          content?.name ||
          content?.email ||
          content?.jobPosition ||
          content?.local
        "
      >
        <div class="font-[IBM600] text-[16px] my-4">Dados do autor</div>

        <div
          class="font-[Roboto400] text-[12px] text-[#676767]"
          v-if="content?.name"
        >
          Autor
        </div>

        <div class="font-[IBM400] text-[16px]" v-if="content?.name">
          {{ content?.name ? content?.name : "" }}
        </div>

        <div
          class="font-[Roboto400] text-[12px] text-[#676767]"
          v-if="content?.email"
        >
          Email
        </div>

        <div class="font-[IBM400] text-[16px]" v-if="content?.email">
          {{ content?.email ? content?.email : "" }}
        </div>

        <div
          class="font-[Roboto400] text-[12px] text-[#676767]"
          v-if="content?.jobPosition"
        >
          Vínculo com Empresa
        </div>

        <div class="font-[IBM400] text-[16px]" v-if="content?.jobPosition">
          {{ content?.jobPosition ? content?.jobPosition : "" }}
        </div>

        <div
          class="font-[Roboto400] text-[12px] text-[#676767]"
          v-if="content?.local"
        >
          Local onde ocorreu
        </div>

        <div class="font-[IBM400] text-[16px]" v-if="content?.local">
          {{ content?.local ? content?.local : "" }}
        </div>

        <div
          class="font-[Roboto400] text-[12px] text-[#676767]"
          v-if="content?.evidencesDesc"
        >
          Possui provas ou evidências?
        </div>

        <div class="font-[IBM400] text-[16px]" v-if="content?.evidencesDesc">
          {{ content?.evidencesDesc ? content?.evidencesDesc : "" }}
        </div>
      </div>

      <div>
        <div class="font-[IBM600] text-[16px] my-4">Arquivos</div>

        <div v-if="files">
          <div
            class="font-[IBM400] text-[16px]"
            v-for="file of files"
            :key="file._id"
          >
            <a :href="file.url" class="text-blue-400" target="_blank">{{
              file.name
            }}</a>
          </div>
        </div>
      </div>
    </div>

    <div
      class="text-left m-3"
      v-if="content?.version === 1 && content.type === 'suggestion'"
    >
      <div class="font-[IBM600] text-[16px] my-4">Descrição</div>

      <div class="font-[IBM400] text-[16px]" v-if="content?.facts">
        {{ content?.facts ? content?.facts : "" }}
      </div>

      <div
        class="font-[Roboto400] text-[12px] text-[#676767]"
        v-if="content?.createdAt"
      >
        Data da Dúvida ou Sugestão
      </div>

      <div class="font-[IBM400] text-[16px]" v-if="content?.createdAt">
        {{ $filters.formatDate(content.createdAt) }}
      </div>

      <div
        class="font-[Roboto400] text-[12px] text-[#676767]"
        v-if="content?.name"
      >
        Autor
      </div>

      <div class="font-[IBM400] text-[16px]" v-if="content?.name">
        {{ content?.name ? content?.name : "" }}
      </div>

      <div
        class="font-[Roboto400] text-[12px] text-[#676767]"
        v-if="content?.email"
      >
        Email
      </div>

      <div class="font-[IBM400] text-[16px]" v-if="content?.email">
        {{ content?.email ? content?.email : "" }}
      </div>
    </div>
  </div>
</template>
<script>
import { contentStorage } from "@/storage/content.js";

export default {
  data() {
    return {
      content: contentStorage.get() || {},
      files: null,
    };
  },
  async created() {
    try {
      this.content = contentStorage.get() || {};
      const response = await this.axios.post(
        "/content/files", 
        {
          company: this.content.company,
          content: this.content._id,
          type: this.content.type
        }
      );

      this.files = response.data.files;
    } catch (error) {
      console.log(error);
    }
  },
};
</script>
