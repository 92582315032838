<template>
  <div class="h-screen">
    <div class="p-10">
      <img src="@/assets/logo.svg" />
    </div>
    <div class="flex flex-col justify-center items-start h-[80%]">
      <div class="bg-blue-300 p-20 lg:ml-96 w-full lg:w-auto">
        <div class="text-[100px] text-left text-[orange] font-extrabold">
          404
        </div>
        <h1 class="text-[24px]">Página não encontrada</h1>
        <h2 class="text-left">
          <router-link
            to="/consultar"
            class="text-blue-500 font-bold italic hover:underline"
            >Fazer uma consulta</router-link
          >
        </h2>
      </div>
    </div>
  </div>
</template>
