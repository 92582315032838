<template>
  <div
    class="flex items-center justify-center my-20 mb-10 border-b border-gray-200 mx-5 pb-5"  
  >
    <div class="mx-2">
      <img
        src="@/assets/images/company-icon.svg"
        alt="Icone de uma pessoa dentro e um crachá  dentro e um circulo com borda groça em tons de azul"
      />
    </div>
    <div><slot></slot></div>
  </div>
</template>
