<template>
  <div class="text-left">
    <input type="file" class="hidden" @change="change" ref="file" multiple />

    <div class="my-5 text-[#333333] text-[16px] font-[IBM600]">
      {{ label }}
    </div>

    <div class="my-5">
      <div
        class="flex items-center justify-center"
        v-for="(file, index) in files"
        :key="file"
      >
        <div class="mr-3">
          <svg
            width="11"
            height="22"
            viewBox="0 0 11 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.5 5V16.5C9.5 18.71 7.71 20.5 5.5 20.5C3.29 20.5 1.5 18.71 1.5 16.5V4C1.5 2.62 2.62 1.5 4 1.5C5.38 1.5 6.5 2.62 6.5 4V14.5C6.5 15.05 6.05 15.5 5.5 15.5C4.95 15.5 4.5 15.05 4.5 14.5V5H3V14.5C3 15.88 4.12 17 5.5 17C6.88 17 8 15.88 8 14.5V4C8 1.79 6.21 0 4 0C1.79 0 0 1.79 0 4V16.5C0 19.54 2.46 22 5.5 22C8.54 22 11 19.54 11 16.5V5H9.5Z"
              fill="#828282"
            />
          </svg>
        </div>
        <div class="grow font-[IBM600] text-[16px] text-[#676767]">
          {{ file.name }}
        </div>
        <div class="ml-3 flex-none w-4 h-4">
          <button @click="remove(index)">
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
                fill="#EB5757"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>

    <button
      class="border-2 border-blue-400 w-full py-3 rounded-md text-blue-400"
      @click="click()"
    >
      <div class="flex">
        <div class="ml-2">
          <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_6686_27320)">
              <path
                d="M19.35 10.54C18.67 7.09 15.64 4.5 12 4.5C9.11 4.5 6.6 6.14 5.35 8.54C2.34 8.86 0 11.41 0 14.5C0 17.81 2.69 20.5 6 20.5H19C21.76 20.5 24 18.26 24 15.5C24 12.86 21.95 10.72 19.35 10.54ZM14 13.5V17.5H10V13.5H7L12 8.5L17 13.5H14Z"
                fill="#5095FD"
              />
            </g>
            <defs>
              <clipPath id="clip0_6686_27320">
                <rect
                  width="24"
                  height="24"
                  fill="white"
                  transform="translate(0 0.5)"
                />
              </clipPath>
            </defs>
          </svg>
        </div>
        <div class="grow text-[Roboto700] text-[16px]">ADICIONAR</div>
      </div>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      default: "",
      type: String,
    },
    modelValue: {
      type: Object,
      default: null,
    },
    name: Array,
    files: Array,
  },
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  data() {
    return {
      storage: [],
    };
  },
  methods: {
    click() {
      this.$refs.file.click();
    },
    change() {
      this.storage.push.apply(this.storage, this.$refs?.file?.files);
      const names = this.storage.map((element) => {
        return { name: element.name };
      });
      const files = this.storage;

      this.$emit("update:names", names);
      this.$emit("update:files", files);

      console.log(names, files)
    },
    remove(index) {
      this.storage = this.storage.filter((item, id) => id !== index);
      const names = this.storage.filter((element) => {
        return element.name;
      });
      const files = this.storage;

      this.$emit("update:names", names);
      this.$emit("update:files", files);
    },
  },
};
</script>
