<template>
  <LoadingBig class="absolute" v-if="loading" />

  <input type="file" class="hidden" @change="change" ref="file" multiple />

  <div id="main" class="bg-white border-l-2 border-r-2 border-gray-200">
    <div class="flex h-full flex-col overflow-hidden">

      <!-- Mensagem padrão -->
      <div class="grow overflow-auto pt-[17%] pb-10 bg-gray-100 flex flex-col items-stretch" ref="chat">
        <div v-if="content?.createdAt" class="bg-[#E8F0FE] rounded-md w-fit p-1 mx-auto my-2">
          <div class="font-[IBM600] text-[12px] text-[#343434] capitalize">
            {{ $filters.formatDay(content?.createdAt) }}
          </div>
        </div>

        <div v-if="content?.type" class="self-end mt-5 max-w-[80%]">
          <div class="bg-[#5095FD] p-3 rounded-md w-fit">
            <div class="flex flex-col text-left text-white text-[14px] font-[IBM400]">
              <div class="font-[Roboto400] text-[12px] text-white text-right">
                {{ $filters.formatDate(content?.createdAt) }}
              </div>
              <div>Fez a {{ getType(content?.type) }}</div>
            </div>
          </div>
        </div>

        <div v-if="hasCompanyFirstComment()" class="mt-5 max-w-[80%] block">
          <div class="bg-white p-3 rounded-md w-fit w-max-1/3 border border-gray-200">
            <div class="flex flex-col text-left text-[#343434] text-[14px] font-[IBM400]">
              <div>{{ getCompanyFirstComment() }}</div>
            </div>
          </div>
        </div>

        <!-- Comentários -->
        <div v-for="comment of comments" :key="comment._id" class="mt-5 max-w-[80%]"
          :class="{ 'self-end': comment.origin === 'person' }">
          <div :class="getOriginDiv1(comment.origin)">
            <div :class="getOriginDiv2(comment.origin)">
              <div class="font-[Roboto400] text-[12px]">{{ $filters.formatDateTime(comment.createdAt) }}</div>
              <div>{{ comment.body }}</div>
              <div v-if="comment?.files?.length > 0">
                <div class="flex" v-for="file of comment.files" :key="file">
                  <div>
                    <img src="@/assets/clip-white.svg" />
                  </div>
                  <div>{{ file }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Rodapé -->
      <FooterWrapper v-if="content.status !== 'closed'" class="bg-gray-200 rounded-md">
        <div v-if="files.length > 0" class="text-left p-2">
          <div class="my-5">
            <div v-for="(file, index) in files" :key="file" class="flex items-center justify-center">
              <div class="mr-3">
                <img src="@/assets/clip-grey.svg" />
              </div>
              <div class="grow font-[IBM600] text-[16px] text-[#676767]">{{ file.name }}</div>
              <div class="ml-3 flex-none w-4 h-4">
                <button @click="remove(index)">
                  <img src="@/assets/remove.svg" />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="flex">
          <textarea
            class="bg-gray-200 border-2 border-orange-300 focus:border-orange-500 focus:outline-none w-full h-18 m-2 p-1 rounded-md"
            ref="textarea" rows="3" v-model="comment"></textarea>
          <div class="flex items-center justify-center px-3 cursor-pointer" @click="openFileSelector()">
            <img src="@/assets/clip-blue.svg" />
          </div>
          <div class="flex items-center justify-center px-3 cursor-pointer" @click="send()">
            <svg width="23" height="18" viewBox="0 0 23 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M0.0105 18L22.05 9L0.0105 0L0 7L15.75 9L0 11L0.0105 18Z"
                :fill="buttonEnabled ? '#5095FD' : '#808080'" />
            </svg>
          </div>
        </div>
      </FooterWrapper>

      <FooterWrapper v-else>
        <div class="rounded-lg text-[24px] text-center py-5">{{ getCompanyChatClosed() }}</div>
      </FooterWrapper>
    </div>
  </div>
</template>

<script>
import FooterWrapper from "@/components/FooterWrapper.vue";
import { companyStorage } from "@/storage/company.js";
import { contentStorage } from "@/storage/content.js";
import LoadingBig from "@/components/LoadingBig.vue";

export default {
  components: {
    FooterWrapper,
    LoadingBig
  },
  data() {
    return {
      loading: false,
      version: "v2",
      content: contentStorage.get() || {},
      comment: null,
      comments: null,
      refreshMessages: null,
      company: "",
      buttonEnabled: true,
      files: [],
    };
  },
  created() {
    this.loading = true;
    this.before();
    this.loading = false;
  },
  beforeUnmount() {
    clearInterval(this.refreshMessages);
  },
  watch: {
    comments() {
      try {
        this.$refs.textarea.focus();
        this.$refs.chat.scrollTop = this.$refs.chat.scrollHeight + 1000;
      } catch (error) {
        console.error(error);
      }
    },
  },
  async mounted() {
    this.loading = true;

    await this.before();

    if (this.content.status !== "closed") {
      try {
        this.$refs.textarea.focus();
        this.$refs.chat.scrollTop = this.$refs.chat.scrollHeight;
      } catch (error) {
        console.error(error);
      }

      await this.refresh();

      this.refreshMessages = setInterval(async () => {
        await this.refresh();
      }, 5000);
    }

    this.loading = false;
  },
  methods: {
    getOriginDiv1(origin) {
      if (origin === 'company') {
        return 'bg-white p-3 rounded-md w-fit w-max-1/3 border border-gray-200';
      } else if (origin === 'person') {
        return 'bg-[#5095FD] p-3 rounded-md w-fit';
      }
    },
    getOriginDiv2(origin) {
      if (origin === 'company') {
        return 'flex flex-col text-left text-[#343434] text-[14px] font-[IBM400]';
      } else if (origin === 'person') {
        return 'flex flex-col text-left text-white text-[14px] font-[IBM400]';
      }
    },
    openFileSelector() {
      this.$refs.file.click();
    },
    async change() {
      try {
        if (this.$refs?.file?.files) this.files.push.apply(this.files, this.$refs?.file?.files);
      } catch (error) {
        console.error(error);
      }
    },
    remove(index) {
      this.files = this.files.filter((_, id) => id !== index);
    },
    async send() {
      this.loading = true;
      try {
        if (!this.buttonEnabled) return;

        if (!this.comment && this.files)

          this.buttonEnabled = false;
        if (this.comment || this.files.length > 0) {
          const type = this.content?.tipo || this.content?.type;
          const paths = {
            v1: `${type}/comment`,
            v2: "public/contents/comment",
          };

          let payload = {
            body: this.comment,
            father_id: this.content._id,
            father_type: type,
          };

          let fileNames = [];

          if (this.files && this.version == 'v2') {
            this.files.forEach(file => {
              fileNames.push(file.name);
            });

            payload.files = fileNames;
          }

          const response = await this.axios.post(`${this.version}/${paths[this.version]}`, payload);

          if (response.status >= 200 && response.status < 400) {
            this.comments = response.data.comments;

            const signedUrls = response.data?.signedUrls;

            if (signedUrls) {
              const urls = signedUrls.map((element) => {
                const file = this.files.filter((file) => {
                  return file.name === element.name;
                });
                return { url: element.url, file };
              });

              const promises = urls.map((element) => {
                return fetch(element.url, {
                  method: "PUT",
                  body: element.file[0],
                });
              });

              const responseFiles = await Promise.allSettled(promises);

              responseFiles.forEach((result, index) => {
                if (result.status === "fulfilled") {
                  if (result.value.ok) {
                    this.toast(`Upload do arquivo ${this.files[index].name} foi bem-sucedido.`, "success", this.$toast);
                    this.files = [];
                  } else {
                    throw new Error(`Upload do arquivo ${urls[index].name} falhou com status ${result.value.status}.`);
                  }
                } else {
                  throw new Error(`Upload do arquivo ${urls[index].name} falhou com erro: ${result.reason}.`);
                }
              });
            }

            // finaliza o envio

            this.$refs.textarea.focus();
            this.$refs.chat.scrollTop = this.$refs.chat.scrollHeight;

            this.comment = "";
          } else {
            throw new Error('Erro ao enviar o comentário');
          }
        } else {
          throw new Error("Selecione um arquivo ou escreva um comentário e depois clique em enviar.")
        }
      } catch (error) {
        this.toast(error.message, "error", this.$toast);
      } finally {
        this.buttonEnabled = true;
        this.loading = false;
      }

    },
    async before() {
      try {
        this.protocol = this.$route?.params?.protocol;

        if (!this.protocol) return;

        await this.getContent();

        companyStorage.setId(this.content.company);

        const response = await this.axios.get(
          `/public/companies/${this.content.company}`,
        );

        companyStorage.setData(response.data);

        this.company = companyStorage.data;
        this.version = `v${this.content.version}`;

        this.$router.replace({
          name: "chat",
          replace: false,
          params: {
            type: this.getType(this.content.type),
            protocol: this.protocol
          },
        });
      } catch (error) {
        this.toast(error, "error", this.$toast);
      }
    },
    async getContent() {
      if (this.protocol) {
        const response = await this.axios.get(
          `/v2/public/contents/${this.protocol}`
        );

        if (response) {
          this.content = response?.data;
          this.content.version = 2;
          // TODO: ver se podemos remover
          contentStorage.set(this.content);
        } else {
          if (response?.status === 404) {
            await this.getComments();

            this.toast("Protocolo não encontrado", "error", this.$toast);
            this.$router.replace({
              name: "consultar",
              replace: false
            });
          } else if (response?.status === 403) {
            this.$router.replace({
              name: "consultar",
              replace: false
            });
            this.toast(response.data.message, "error", this.$toast);
          } else {
            this.$router.replace({
              name: "consultar",
              replace: false
            });
            this.toast(response, "error", this.$toast);
          }
        }
      }
    },
    async getComments() {
      const response = await this.axios.get(`/v1/comments/${this.protocol}`)
      if (response) {
        this.content = response?.data;
        this.content.version = 1;
        contentStorage.set(this.content);

      } else {
        console.error(response);
        this.toast(
          response?.data?.message,
          "error",
          this.$toast
        );
      }

    },
    getType(type) {

      const label = {
        complaint: "denuncia",
        suggestion: "sugestao",
        request: "solicitacao",
      }[type];

      return label;
    },
    async refresh() {
      try {
        const paths = { v1: "comment", v2: "public/contents" };
        if (this.content.protocol) {
          const response = await this.axios.get(
            `/${this.version}/${paths[this.version]}/${this.content.protocol}`
          );

          this.comments = response?.data?.comments;
        }

      } catch (error) {
        console.error(error);
      }
    },
    hasCompanyFirstComment() {
      const type = this.content?.type;
      return typeof this.company === "object" && type;
    },
    getCompanyFirstComment() {
      const type = this.content?.type;
      return typeof this.company === "object" && type ? this.company[`${type}FirstComment`] : "";
    },
    getCompanyChatClosed() {
      if (this.company && this.content && this.content.type) {
        return this.company[`${this.content.type}ChatClosed`] || "";
      }
      return "";
    }
  },
};
</script>
