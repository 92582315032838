import { reactive } from "vue";

export const companyStorage = reactive({
  id: null,
  data: null,

  setId(id) {
    this.id = id;
  },
  setData(data) {
    this.data = data;
  },
});
