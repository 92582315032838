<template>
  <div id="main" class="bg-white border-l-2 border-r-2 border-gray-200 px-5 pb-10">
    <CompanyLabel> {{ company?.data?.name }} </CompanyLabel>
    <slot></slot>
  </div>
</template>

<script>
import CompanyLabel from "@/components/CompanyLabel.vue";
import { companyStorage } from "@/storage/company.js";

export default {
  beforeCreate() {
    if (this.$route?.params?.id !== undefined) {
      companyStorage.setId(this.$route?.params?.id);
      this.axios
        .get(`/public/companies/${this.$route.params.id}`)
        .then((response) => {
          companyStorage.setData(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  },
  components: {
    CompanyLabel,
  },
  data() {
    return {
      company: companyStorage,
    };
  },
};
</script>
