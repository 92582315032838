<template>
  <div>
    <metainfo>
      <template v-slot:title="{ content }">{{ content }}</template>
    </metainfo>
  </div>
</template>

<script>
import { useMeta } from 'vue-meta';

export default {
  props: {
    title: {
      type: String,
      default: "Página sem título",
    },
    description: {
      type: String,
      default: "Página sem descrição",
    },
    path: {
      type: String,
      default: "",
    },
  },

  setup(props) {
    useMeta({
      title: props.title,
      description: props.description,
      meta: [
        {
          name: "robots",
          content: process.env.VUE_APP_ENV === "hml" ? "noindex" : "index,follow",
        },
      ],
      link: [
        {
          rel: "canonical",
          href: `https://inicio.${process.env.VUE_APP_ENV === "hml" ? "hml." : ""}bcompliance.com${props.path}`,
        },
      ],
      htmlAttrs: {
        lang: "pt-BR",
      },
    });
  },
};
</script>
