<template>
  <MetaTags :title="getLabel" :description="$route.meta.description" :path="$route.href" :key="$route.href" />
  <div id="body" v-if="$route.name !== 'notfound'">
    <MainHeader :double="$route.meta.type === 'chat'" class="absolute top-0 left-0 w-full bg-white">
      {{ getLabel }}
      <template v-slot:subtitle v-if="$route.meta.type === 'chat'">
        {{ $filters.formatDate(content.createdAt) }}
      </template>
      <template v-slot:double>
        <div class="flex flex-col justify-center items-center">
          <div>
            {{ content.details }}
          </div>
          <div>
            <router-link :to="{ name: 'details', params: { content: JSON.stringify(content), type: content.type } }">
              <div class="border text-[#2D77E6] border-[#E0E0E0] rounded-md w-36 cursor-pointer mt-2">
                Ver detalhes
              </div>
            </router-link>
          </div>
        </div>
      </template>
    </MainHeader>
    <div class="h-14" :class="{
    'h-14': !($route.meta.type === 'chat'),
    'mt-50': $route.meta.type === 'chat',
  }"></div>
    <div id="content" :class="{
    'footer-structure': $route.name === 'content-terms',
    'no-footer': $route.name !== 'content-terms',
    'bg-white': $route.name === 'content-success',
    'bg-gray-50': $route.name !== 'content-success',
  }">
      <router-view />
    </div>
  </div>
  <router-view v-else />
</template>
<script>
import MainHeader from "@/components/MainHeader.vue";
import MetaTags from "@/components/MetaTags.vue";
import { contentStorage } from "@/storage/content.js";
export default {
  name: "App",
  components: {
    MainHeader,
    MetaTags,
  },
  data() {
    return {
      content: { type: "" },
    };
  },
  mounted() {
    this.content = contentStorage.get() || { type: "" };
  },
  computed: {
    getLabel() {
      // this.content = contentStorage.get() || { type: null };
      
      if (this.content.type === null) return "";
      let label = this.$route?.meta?.label;
      const type = this.content.type;

      try {
        const labelJSON = JSON.parse(label);
        const aux = labelJSON[type];
        label = aux;
      } catch (error) {
        // do nothing
      }

      if (type && typeof label === "object") {
        console.log(this.content.type, label[type]);
        return label[type];
      } else if (!type && typeof label === "object") {
        return "Carregando...";
      }
      else {
        return "Canal de comunicação"
      }


    }
  }
};
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  height: 100vh;
  overflow: hidden;
}

#content {
  display: grid;
  grid-template-columns: repeat(14, 1fr);
  height: 100%;
}

#main {
  grid-column: 5/11;
  grid-row: 1/2;
  overflow: scroll;
}

.footer-structure {
  grid-template-rows: 85% 15%;
}

@media (max-width: 1024px) {

  /* TODO estava 320 */
  #main {
    grid-column: 1/16;
    grid-row: 1/2;
    overflow: scroll;
  }

  .footer-structure {
    grid-template-rows: 85.5% 14.5%;
  }
}

/* @media (max-width: 1024px) {
  #main {
    grid-column: 5/12;
    grid-row: 1/2;
    overflow: scroll;
  }

  .footer-structure {
    grid-template-rows: 75% 25%;
  }
} */

#footer {
  grid-column: 1/15;
  grid-row: 2;
}

.no-footer {
  grid-template-rows: 100%;
}

#body {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
</style>
