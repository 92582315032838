<template>
  <div
    class="flex justify-center items-center rounded-md border-2 border-[#5095FD] text-[#5095FD] px-5 py-3 my-3 font-[Roboto700] cursor-pointer"
  >
    <div>
      <svg
        v-if="icon === 'hamburguer-check'"
        width="21"
        height="14"
        viewBox="0 0 21 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 4H0V6H12V4ZM12 0H0V2H12V0ZM0 10H8V8H0V10ZM19.5 5.5L21 7L14.01 14L9.5 9.5L11 8L14.01 11L19.5 5.5Z"
          fill="#5095FD"
        />
      </svg>
      <svg
        v-if="icon === 'letter'"
        width="22"
        height="16"
        viewBox="0 0 22 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.3378 0H2.29882C1.12739 0 0.179595 0.9 0.179595 2L0.168945 14C0.168945 15.1 1.12739 16 2.29882 16H19.3378C20.5092 16 21.4676 15.1 21.4676 14V2C21.4676 0.9 20.5092 0 19.3378 0ZM19.3378 4L10.8183 9L2.29882 4V2L10.8183 7L19.3378 2V4Z"
          fill="#5095FD"
        />
      </svg>
    </div>
    <div class="grow"><slot></slot></div>
  </div>
</template>
<script>
export default {
  props: {
    icon: {
      type: String,
    },
  },
  //   TODO: ver como conseguir uma alternativa a isso, acho que fica pouco elegante...
  //   computed: {
  //     getImagePath() {
  //       return require("@/assets/images/" + this.icon + ".svg");
  //     },
  //   },
};
</script>
