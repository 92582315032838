export const descriptions = {
  "Confirmação da existência de tratamento de dados":
    "Solicitar informação sobre se os seus dados pessoais estão sendo objeto de tratamento pela empresa / profissional selecionado. Tratamento de dados é toda operação realizada com dados pessoais, como as que se referem a coleta, produção, recepção, classificação, utilização, acesso, reprodução, transmissão, distribuição, processamento, arquivamento, armazenamento, eliminação, avaliação ou controle da informação, modificação, comunicação, transferência, difusão ou extração.",
  "Acesso aos dados":
    "Solicitar que a empresa / profissional selecionado forneça acesso aos seus dados pessoais para consulta. Dado pessoal: informação relacionada a pessoa natural identificada ou identificável.",
  "Correção de dados incompletos, inexatos ou desatualizados":
    "Solicitar correções nos seus dados pessoais que a empresa / profissional selecionado esteja tratando.",
  "Anonimização, bloqueio ou eliminação de dados desnecessários, excessivos ou tratados em desconformidade com a LGPD":
    "Solicitar que seus dados sejam anonimizados (utilização de meios técnicos razoáveis e disponíveis no momento do tratamento, por meio dos quais um dado perde a possibilidade de associação, direta ou indireta, a um indivíduo); ou, ainda, bloqueados ou eliminados dos banco de dados da empresa / profissional selecionado por não estarem de acordo com os princípios e determinações da LGPD.",
  "Portabilidade dos dados a outro fornecedor de serviço ou produto":
    "Solicitar que a empresa / profissional selecionado forneça todos os seus dados pessoais que possua em seus banco de dados em um formato acessível para que sejam transferidos a outro fornecedor de serviço ou produto.",
  "Eliminação dos dados pessoais tratados com o consentimento do titular":
    "Solicitar a eliminação dos dados pessoas que são tratados pela empresa / profissional selecionado com base no seu consentimento. O consentimento é a manifestação livre, informada e inequívoca pela qual o titular concorda com o tratamento de seus dados pessoais para uma finalidade determinada.",
  "Informação das entidades públicas e privadas com as quais o controlador realizou uso compartilhado de dados":
    "Solicitar informações sobre os dados que a empresa / profissional selecionado tenha utilizado de forma compartilhada com terceiros. O uso compartilhado de dados é a comunicação, difusão, transferência internacional, interconexão de dados pessoais ou tratamento compartilhado de bancos de dados pessoais por órgãos e entidades públicos no cumprimento de suas competências legais, ou entre esses e entes privados, reciprocamente, com autorização específica, para uma ou mais modalidades de tratamento permitidas por esses entes públicos, ou entre entes privados.",
  "Informação sobre a possibilidade de não fornecer consentimento e sobre as consequências da negativa":
    "Solicitar informações sobre se é possível deixar de fornecer o consentimento para o tratamento de determinado dado pessoal por parte da empresa / profissional selecionado e quais serão as consequências para você deste não consentimento.",
  "Revogação do consentimento":
    "Solicitar a retirada de um consentimento fornecido anteriormente para que a empresa / profissional selecionado pudesse realizar algum tratamento com seu dado pessoal.",
};
