import { reactive } from "vue";

export const contentStorage = reactive({
  data: null,

  set(data) {
    this.data = data;
  },
  get() {
    return this.data;
  }
});
