<template>
  <component :is="to !== null ? 'router-link' : 'span'" :to="to">
    <component
      :is="accept ? 'button' : 'span'"
      class="w-full flex p-4"
      :class="{
        'bg-blue-400 text-white': accept,
        'bg-gray-400 text-black': !accept,
      }"
    >
      <div class="grow uppercase">{{ title }}</div>
      <div>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12 4L10.59 5.41L16.17 11H4V13H16.17L10.59 18.59L12 20L20 12L12 4Z"
            fill="white"
          />
        </svg>
      </div>
    </component>
  </component>
</template>
<script>
export default {
  props: {
    accept: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "You need give a title to this button",
    },
    to: {
      type: String,
      default: null,
    },
  },
};
</script>
