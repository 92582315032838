<template>
  <textarea
    :id="id"
    :name="name"
    cols="30"
    rows="5"
    :placeholder="placeholder"
    class="bg-gray-200 rounded-sm w-full p-3 mt-3 font-[IBM500]"
    :class="{
      'border-b border-b-[#9CBFFF]': !required,
      ' border-b border-b-red-400': required,
    }"
    :value="modelValue"
    @input="$emit('update:modelValue', $event.target.value)"
  />
</template>
<script>
export default {
  props: {
    placeholder: {
      type: String,
      default: null,
    },
    id: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: Object,
      default: null,
    },
  },
  emits: ["update:modelValue"],
};
</script>
