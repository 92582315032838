<template>
  <div>
    <div class="flex items-center h-14" :class="{
      'border-b border-gray-300': !double
    }">

      <!-- TODO: colocar o logo na pagina final em cada fluxo -->
      <button class="m-5" @click="$router.back()">
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M8 0L9.41 1.41L3.83 7H16V9H3.83L9.41 14.59L8 16L0 8L8 0Z"
            fill="#5095FD"
          />
        </svg>
      </button>
      <div class="grow text-center text-[#343434] text-[18px] font-[IBM500]">
        <div class="flex flex-col">
          <div>
            <slot></slot>
          </div>
          <div class="font-[Roboto400] text-[11px] text-[#676767]">
            <slot name="subtitle"></slot>
          </div>
        </div>
      </div>
      <div class="m-5">&nbsp;</div>
    </div>

    <div v-if="double" class="py-5"  :class="{
      'border-b border-gray-300': double
    }">
      <slot name="double"></slot>
    </div>
  </div>
</template>
<script>

export default{
  props: {
    double: {
      type: Boolean,
      default: false
    }
  }
}
</script>