import { createApp } from "vue";
import App from "./App.vue";
import axios from "axios";
import VueAxios from "vue-axios";
import router from "./router";
import { VueClipboard } from "@soerenmartius/vue3-clipboard";
import Toaster from "@meforma/vue-toaster";
import moment from "moment";

import { createMetaManager } from "vue-meta";

import "moment/locale/pt-br";
moment.locale("pt-br");

import "./assets/css/index.css";

const ENVIRONMENT = process.env.VUE_APP_ENV;

console.log("ambiente", ENVIRONMENT);

const API = {
  hml: "xco1wwkt3c",
  prod: "iv5qgrg57g",
  dev: "http://localhost:3003/api",
};

const REGION = {
  hml: "us-east-2",
  prod: "sa-east-1",
  dev: "",
};

const path =
  ENVIRONMENT === "dev"
    ? API[ENVIRONMENT]
    : `https://${API[ENVIRONMENT]}.execute-api.${REGION[ENVIRONMENT]}.amazonaws.com/production/api`;

axios.defaults.baseURL = path;

const app = createApp(App);

app.config.globalProperties.VUE_APP_ENV = ENVIRONMENT;

app.config.globalProperties.toast = (message, type, toast, time=3000) => {
  toast[type](message);
  setTimeout(toast.clear, time);
};

app.config.globalProperties.$filters = {
  formatDate(date) {
    if (date) {
      return moment(date).format("DD/MM/YYYY");
    }
  },
  formatDateTime(date) {
    if (date) {
      return moment(date).format("DD/MM/YYYY · HH[h]mm");
    }
  },
  formatDay(date) {
    return moment(date).format("ddd, D");
  },
  truncate(text, length, suffix) {
    if (text.length > length) {
      return text.substring(0, length) + suffix;
    } else {
      return text;
    }
  },
};

const metaManager = createMetaManager();

app
  .use(router)
  .use(Toaster)
  .use(VueAxios, axios)
  .use(metaManager)
  .use(VueClipboard)
  .mount("#app");
