<template>
  <div id="main" class="bg-white border-l-2 border-r-2 border-gray-200 px-5">
    <div class="flex items-center justify-center h-full flex-col">
      <div class="flex flex-col justify-center items-center grow">
        <div>
          <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle opacity="0.4" cx="28" cy="28" r="28" fill="#D6E5FF" />
            <circle cx="28" cy="28" r="20" fill="white" />
            <path
              d="M30 26H18V28H30V26ZM30 22H18V24H30V22ZM18 32H26V30H18V32ZM37.5 27.5L39 29L32.01 36L27.5 31.5L29 30L32.01 33L37.5 27.5Z"
              fill="#5095FD" />
          </svg>
        </div>
        <div class="font-[IBM500] text-[24px] text-[#343434]">
          Qual o protocolo da comunicação?
        </div>
        <div class="text-[IBM500] text-[16px] text-[#828282]">
          Digite o número para acessar
        </div>

        <InputComponent :required="false" placeholder="Nº de protocolo" v-model="protocol" @keyup.enter="next">
        </InputComponent>
      </div>
      <FooterWrapper>
        <ButtonComponent :accept="isValidProtocol" title="consultar" @click="next" />
      </FooterWrapper>
    </div>
  </div>
</template>
<script>
import InputComponent from "@/components/InputComponent.vue";
import FooterWrapper from "@/components/FooterWrapper.vue";
import ButtonComponent from "@/components/ButtonComponent.vue";

export default {
  data() {
    return {
      protocol: "",
    };
  },
  components: {
    InputComponent,
    FooterWrapper,
    ButtonComponent,
  },
  computed: {
    isValidProtocol() {
      return new RegExp(/^[0-9a-fA-F]{24}$/, 'g').test(this.protocol);
    },
  },
  methods: {
    next() {
      if (this.isValidProtocol) {
        this.$router.push({
          name: "chat",
          replace: false,
          params: {
            type: "carregando...",
            protocol: this.protocol
          },
        });
      }
    },
  },
};
</script>
