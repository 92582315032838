export const items = [
  "Escolha o tipo",
  "Confirmação da existência de tratamento de dados",
  "Acesso aos dados",
  "Correção de dados incompletos, inexatos ou desatualizados",
  "Anonimização, bloqueio ou eliminação de dados desnecessários, excessivos ou tratados em desconformidade com a LGPD",
  "Portabilidade dos dados a outro fornecedor de serviço ou produto",
  "Eliminação dos dados pessoais tratados com o consentimento do titular",
  "Informação das entidades públicas e privadas com as quais o controlador realizou uso compartilhado de dados",
  "Informação sobre a possibilidade de não fornecer consentimento e sobre as consequências da negativa",
  "Revogação do consentimento",
];
